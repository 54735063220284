
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  reactive,
  computed,
  watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import { IUser } from "@/store/modules/valored/UserModule";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "edit-company-setting-modal",
  components: { ErrorMessage, Field, Form },
  props: {
    customer: {
      type: Object as PropType<IUser>,
      required: true,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();

    const store = useStore();
    const currentCustomer = reactive(computed(() => props.customer as IUser));
    const currentYear = ref<number | null>(null);
    const currentYearlyParameters = reactive(
      computed(() => {
        if (currentYear.value && currentCustomer.value.yearlyParameters) {
          return currentCustomer.value.yearlyParameters.filter(
            (item) => item.azienda_yearly_parameters.year == currentYear.value
          )[0];
        }
        return null;
      })
    );

    const editCompanySettingModalRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const selectLists = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const editCompanySettingSchema = Yup.object().shape({
      denom: Yup.string()
        .required(translate("COMPANY_NAME_REQUIRED"))
        .label("Company name "),
      settore: Yup.string()
        .required(translate("INDUSTRIAL_SECTOR_REQUIRED"))
        .label("Sector name"),
      fatturato: Yup.string()
        .required(translate("USERNAME_REQUIRED"))
        .label("Fatturato"),
      employees_range: Yup.string()
        .required(translate("EMPLOYEE_RANGE_REQUIRED"))
        .nullable()
        .label(translate("Employee Range")),
      is_associated: Yup.string()
        .required(translate("MEMBERSHIP_REQUIRED"))
        .label("Webside"),
      is_quoted: Yup.string()
        .required(translate("LISTING_REQUIRED"))
        .label("Country"),
      company_type: Yup.string()
        .required(translate("COMPANY_TYPE_REQUIRED"))
        .label("Company Type"),
    });

    const submit = (values) => {
      loading.value = true;

      store
        .dispatch(Actions.UPDATE_COMPANY_PROFILE_ADMIN, {
          id: props.customer?.id,
          year: currentYear.value,
          ...values,
        })
        .then(() => {
          successMessage(
            t("ADMIN_TABLE_UPDATE_COMPANY_SUCCESS_MESSAGE")
          );
          hideModal(editCompanySettingModalRef.value);
          store.dispatch(Actions.REQ_USER_LIST);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);
    });

    watch(currentCustomer, (val) => {
      if (val.yearlyParameters && val.yearlyParameters[0].azienda_yearly_parameters.year)
        currentYear.value = val.yearlyParameters[0].azienda_yearly_parameters.year as number;
      else {
        currentYear.value = null;
      }
    });

    return {
      editCompanySettingModalRef,
      editCompanySettingSchema,
      currentCustomer,
      currentYear,
      currentYearlyParameters,
      translate,
      translateKey,
      selectLists,
      submit,
      loading,
      locale
    };
  },
});
