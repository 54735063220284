
import { defineComponent, ref, PropType } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import { translate } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import { IUser } from "@/store/modules/valored/UserModule";

export default defineComponent({
  name: "reset-password-modal",
  components: { ErrorMessage, Field, Form },
  props: {
    customer: Object as PropType<IUser>,
  },
  setup(props) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const resetPasswordModalRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const changePassword = Yup.object().shape({
      newpassword: Yup.string()
        .min(6, translate("PASSWORD_LENGTH_VALIDATION"))
        .required(translate("PASSWORD_REQUIRED"))
        .label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required(translate("PASSWORD_CONFIRMATION_REQUIRED"))
        .oneOf([Yup.ref("newpassword"), null], translate("PASSWORD_MUST_MATCH"))
        .label("Password Confirmation"),
    });

    const submit = (values, { resetForm }) => {
      loading.value = true;
      store
        .dispatch(Actions.UPDATE_PASSWORD_ADMIN, {
          ...values,
          id: props.customer?.id,
        })
        .then(() => {
          successMessage("Password is successfully changed!");
          hideModal(resetPasswordModalRef.value);
          resetForm();
        })
        .finally(() => {
          loading.value = false;
          resetForm();
        });
    };

    return {
      resetPasswordModalRef,
      changePassword,
      submit,
      formRef,
      loading,
      translate
    };
  },
});
