
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import axios from "axios";
import { translate } from "@/hooks/TranslateService";

export default defineComponent({
    name: "export-xlsx-modal",
    components: {},
    setup() {
        const exportXlsxModalRef = ref<null | HTMLFormElement>(null);

        const selectedYear = ref<number>(2017);
        const yearsList = ref(
            Array.from({length: new Date().getFullYear() - 2017}, (v, k) => k + 2017)
        );

        const spinnerDownloadVisible = ref<boolean>(false);

        const selectedReportType = ref<string>("iii");

        const onChangeReportType = () => {
            if (selectedReportType.value === "sp") {
                selectedYear.value = 2021;
                yearsList.value = Array.from({length: new Date().getFullYear() - 2021}, (_, k) => k + 2021);
            } else {
                selectedYear.value = 2017;
                yearsList.value = Array.from({length: new Date().getFullYear() - 2017}, (_, k) => k + 2017);
            }
        };

        const onHideModal = () => {
            hideModal(exportXlsxModalRef.value);
        };

        const onSubmitModal = () => {
            spinnerDownloadVisible.value = true;

            axios({
                method: "get",
                url: `/xlsx/${selectedYear.value}/${selectedReportType.value}`,
                responseType: "blob"
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `report-${selectedYear.value}-${selectedReportType.value}.xlsx`
                );
                link.click();
                window.URL.revokeObjectURL(url);

                spinnerDownloadVisible.value = false
            })
            .catch(error => {
              console.error(error);
            });
        };
    
        return {
            exportXlsxModalRef,
            selectedYear,
            yearsList,
            selectedReportType,
            onChangeReportType,
            onHideModal,
            spinnerDownloadVisible,
            onSubmitModal,
            translate
        };
    },
});
