
import { defineComponent, reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "export-customers-modal",
  components: {},
  props: ["data", "years"],
  setup(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const exportCustomerCsvModalRef = ref<null | HTMLFormElement>(null);
    const selectedYear = ref<number>(2017);
    const yearsList = reactive(computed(() => store.getters.getYearsList));
    const settoriList = reactive(computed(() => store.getters.getSettoriList));

    const getYearlySettore = (yearlyArr) => {
      const result = yearlyArr.filter(
        (item) => item.year == selectedYear.value
      );
      if (result.length > 0) {
        return settoriList.value[result[0].settore].descrizione;
      } else return "";
    };

    const submit = () => {
      loading.value = true;
      let csv =
        "ID, RAGIONE SOCIALE, ULTIMO SETTORE, PARTITA IVA, DATA CREAZIONE, USERNAME, EMAIL, ATTIVA\n";
      props.data.forEach((row) => {
        console.log(row);
        csv += row.id + ",";
        csv += row.denom + ",";
        csv += getYearlySettore(row.yearlyParameters) + ",";
        csv += row.p_iva.toString() + ",";
        csv +=
          new Date(row.user_creation_timestamp).toLocaleString("it-IT") + ",";
        csv += row.user_name + ",";
        csv += row.email + ",";
        if (row.user_active) csv += "true,";
        else csv += "false,";
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "Users_" + Date.now() + ".csv";
      anchor.click();
      loading.value = false;
      
      hideModal(exportCustomerCsvModalRef.value);
    };

    const onHideModal = () => {
      hideModal(exportCustomerCsvModalRef.value);
    };

    return {
      exportCustomerCsvModalRef,
      selectedYear,
      yearsList,
      submit,
      loading,
      onHideModal,
    };
  },
});
