
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  watch,
  nextTick,
} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerCsvModal from "@/components/divere/modals/ExportCustomerCsvModal.vue";
import ExportCustomerCsvModalTest from "@/components/divere/modals/ExportCustomerCsvModalCopy.vue";
import ExportXlsxModal from "@/components/divere/modals/ExportXlsxModal.vue";
import ResetPasswordModal from "@/components/divere/modals/admin-user-action/ResetPasswordModal.vue";
import EditAccountSettingModal from "@/components/divere/modals/admin-user-action/EditAccountSettingModal.vue";
import EditCompanySettingModal from "@/components/divere/modals/admin-user-action/EditCompanySettingModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { IUser } from "@/store/modules/valored/UserModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import { successMessage } from "@/core/services/AlertService";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  name: "user-listing",
  props: ["type"],
  components: {
    Datatable,
    ExportXlsxModal,
    ExportCustomerCsvModal,
    ExportCustomerCsvModalTest,
    ResetPasswordModal,
    EditAccountSettingModal,
    EditCompanySettingModal,
  },
  setup(props) {
    const { t } = useI18n();

    const store = useStore();
    const checkedCustomers = ref([]);
    const tableLoading = ref<boolean>(false);
    const selectedCustomer = ref<IUser | null>(null);
    // const resetPasswordModalRef =
    //   ref<InstanceType<typeof ResetPasswordModal>>();
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "ID",
        key: "id",
        sortable: true,
      },
      {
        name: "Company ID",
        key: "azienda_id",
        sortable: true,
      },
      {
        name: translate("BUSINEESS_NAME"),
        key: "denom",
        sortable: true,
      },
      {
        name: translate("LAST_SECTOR"),
        key: "settore",
        sortable: true,
      },
      {
        name: translate("VAT_NUMBER"),
        key: "p_iva",
        sortable: true,
      },
      {
        name: translate("CREATION_DATE"),
        key: "azienda_creation_timestamp",
        sortable: true,
      },
      {
        name: translate("USER_NAME"),
        key: "user_name",
        sortable: true,
      },
      {
        name: translate("EMAIL"),
        key: "email",
        sortable: true,
      },
      {
        name: translate("ACTIVE"),
        key: "user_active",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const agentList = reactive(
      computed(() => {
        if (props.type === "active") {
          return store.getters.getAgentList;
        }
        return store.getters.getInactiveAgentList;
      })
    );

    const title = reactive(
      computed(() => {
        if (props.type == "active") {
          return t("ADMIN_PANEL_APPROVED_USERS");
        }
        return t("ADMIN_PANEL_TO_APPROVE_USERS");
      })
    );

    const settoriList = reactive(computed(() => store.getters.getSettoriList));
    const tableData = ref<Array<IUser>>([]);

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (customer) => {
      deleteCompany(customer.azienda_id);

      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === customer.id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...agentList.value);
      if (search.value !== "") {
        let results: Array<IUser> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateFormatter = (date) => {
      let d = date;
      return [
        d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
        d.getFullYear(),
      ].join("/");
    };

    const exportAccounts = (type: string) => {
      axios({
          method: "get",
          url: `/xlsx/users?type=${type}`,
          responseType: "blob"
      })
      .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "users.xlsx");
          link.click();
          window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
      });
    }

    const userNavigate = (user: IUser) => {
      localStorage.removeItem("to_year_admin");
      localStorage.removeItem("simulate_user");
      localStorage.setItem("simulate_user", JSON.stringify(user));
      store.dispatch(Actions.SET_SELECTED_USER, user);
    };

    const selectCustomer = (customer) => {
      selectedCustomer.value = customer;
    };

    const activateUser = (id: number) => {
      tableLoading.value = true;
      store
        .dispatch(Actions.RESET_USER_ACTIVATION_ADMIN, id)
        .then(() => {
          successMessage("Successfully activated!")
            .then(() => location.reload());
        })
        .finally(() => {
          tableLoading.value = false;
        });
    };

    const deactivateUser = (id: number) => {
      tableLoading.value = true;
      store
        .dispatch(Actions.RESET_USER_DEACTIVATION_ADMIN, id)
        .then(() => {
          successMessage("Successfully deactivated!")
            .then(() => location.reload());
        })
        .finally(() => {
          tableLoading.value = false;
        });
    };

    const refuseUser = (id: number) => {
      tableLoading.value = true;
      store
        .dispatch(Actions.REFUSE_USER_INVITATION, id)
        .then(() => {
          successMessage("Successfully refused!")
            .then(() => location.reload());
        })
        .finally(() => {
          tableLoading.value = false;
        });
    };

    const deleteCompany = (id: number) => {
      tableLoading.value = true;
      store
        .dispatch(Actions.DEL_COMPANY, id)
        .then(() => {
          successMessage("Company successfully deleted!").then(() => location.reload());
        })
        .finally(() => {
          tableLoading.value = false;
        });
    };

    watch(agentList, (val) => {
      tableData.value.splice(0, val.length, ...val);
      nextTick(() => {
        MenuComponent.reinitialization();
      });
    });

    return {
      selectedCustomer,
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      settoriList,
      translateKey,
      translate,
      dateFormatter,
      userNavigate,
      selectCustomer,
      activateUser,
      deactivateUser,
      tableLoading,
      title,
      refuseUser,
      deleteCompany,
      exportAccounts
    };
  },
});
