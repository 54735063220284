
import { defineComponent, ref, onMounted } from "vue";
import ResetPasswordModal from "@/components/divere/modals/admin-user-action/ResetPasswordModal.vue";
import EditAccountSettingModal from "@/components/divere/modals/admin-user-action/EditAccountSettingModal.vue";
import EditCompanySettingModal from "@/components/divere/modals/admin-user-action/EditCompanySettingModal.vue";
import UserTable from "@/components/valored/user-list/UserTable.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IUser } from "@/store/modules/valored/UserModule";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "user-listing",
  components: {
    // Datatable,
    // ExportCustomerCsvModal,
    ResetPasswordModal,
    EditAccountSettingModal,
    EditCompanySettingModal,
    UserTable,
  },
  setup() {
    const selectedCustomer = ref<IUser | null>(null);
    const store = useStore();

    const selectCustomer = (customer) => {
      selectedCustomer.value = customer;
    };

    onMounted(() => {
      store.dispatch(Actions.REQ_USER_LIST);
      MenuComponent.reinitialization();
    });

    return {
      selectCustomer,
      selectedCustomer,
    };
  },
});
