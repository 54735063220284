import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTable = _resolveComponent("UserTable")!
  const _component_ResetPasswordModal = _resolveComponent("ResetPasswordModal")!
  const _component_EditAccountSettingModal = _resolveComponent("EditAccountSettingModal")!
  const _component_EditCompanySettingModal = _resolveComponent("EditCompanySettingModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UserTable, { type: "inactive" }),
      _createVNode(_component_UserTable, { type: "active" })
    ]),
    _createVNode(_component_ResetPasswordModal, { customer: _ctx.selectedCustomer }, null, 8, ["customer"]),
    _createVNode(_component_EditAccountSettingModal, { customer: _ctx.selectedCustomer }, null, 8, ["customer"]),
    _createVNode(_component_EditCompanySettingModal, { customer: _ctx.selectedCustomer }, null, 8, ["customer"])
  ], 64))
}