
import { defineComponent, PropType, ref, reactive, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import { translate } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import { IUser } from "@/store/modules/valored/UserModule";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "edit-account-setting-modal",
  components: { ErrorMessage, Field, Form },
  props: {
    customer: {
      type: Object as PropType<IUser>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const store = useStore();
    const currentCustomer = reactive(computed(() => props.customer as IUser));
    const formRef = ref<null | HTMLFormElement>(null);
    const editAccountSettingModalRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const editAccountSettingSchema = Yup.object().shape({
      user_name: Yup.string()
        .matches(
          /^[a-zA-Z0-9]{2,64}$/,
          translate("USERNAME_CHARACTER_VALIDATION")
        )
        .required(translate("USERNAME_REQUIRED")),
      email: Yup.string()
        .required()
        .email()
        .label("Email"),
      ruolo_aziendale: Yup.string()
        .matches(/^[a-z A-Z]{0,64}$/, translate("REGISTRATION_ROLE_INVALID"))
        .nullable(),
      telefono: Yup.string()
        .matches(/^[0-9]{8,10}$/, translate("REGISTRATION_PHONE_INVALID"))
        .nullable(),
    });

    const submit = (values, { resetForm }) => {
      loading.value = true;
      store
        .dispatch(Actions.UPDATE_PROFILE_ADMIN, {
          ...values,
          id: props.customer?.id,
        })
        .then(() => {
          successMessage(t("ADMIN_TABLE_UPDATE_USER_SUCCESS_MESSAGE"));
          hideModal(editAccountSettingModalRef.value);
          store.dispatch(Actions.REQ_USER_LIST);
        })
        .finally(() => {
          loading.value = false;
          resetForm();
        });
    };

    return {
      editAccountSettingModalRef,
      editAccountSettingSchema,
      currentCustomer,
      translate,
      submit,
      formRef,
      loading,
    };
  },
});
